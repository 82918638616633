import { useState, useEffect } from 'react';
import { getProductData } from '../lib/getProductData';
import { supabase } from '../lib/initSupabase';

import RatingContainer from '../components/RatingContainer';
import ReviewsList from '../components/ReviewsList';
import Modal from '../components/Modal';

import '../styles/global.css';

const Product = () => {
  const [productData, setProductData] = useState({});
  const [showModal, setShowModal] = useState(false);

  // Get product's title and reviews from db
  useEffect(() => {
    getProductData().then(res => setProductData(res));
  }, []);

  // Subscribe to realtime changes in the reviews table
  supabase.from('reviews').on('INSERT', payload => {
    setProductData((prevState) => {
      if (prevState.reviews.some(review => review.id === payload.new.id)) {
        return prevState;
      } else {
        let total = 0;

        for (let i = 0; i < prevState.reviews.length; i++) {
          total += prevState.reviews[i].score;
        }

        let score = (total + payload.new.score) / (prevState.reviews.length + 1);
        return { ...prevState, score, reviews: [...prevState.reviews, payload.new] };
      }
    });
  }).subscribe();

  return (
    <div className="page">
      <h1 className="title">{productData.title}</h1>
      <RatingContainer productData={productData} showModal={showModal} setShowModal={setShowModal} />
      <ReviewsList productData={productData} />
      <Modal showModal={showModal} setShowModal={setShowModal} productData={productData} />
    </div>
  );
};

export default Product;