import renderStars from "../lib/renderStars";

const ReviewsList = ({ productData }) => {
    return <ul id="list-reviews">
        <h3 id="title-list-reviews">Reviews</h3>
        {productData.reviews ? productData.reviews.map((review, k) => {
            return <li className="container-reviews" key={k}>
                <div className="container-stars">
                    {renderStars(review.score).map(star => star)}
                </div>
                <span className="score-review">{review.score}</span>
                <p className="text-review">{', ' + review.text}</p>
            </li>;
        }) : ''}
    </ul>;
};

export default ReviewsList;