import { useState, useEffect, useRef } from "react";
import { supabase } from "../lib/initSupabase";

import Star from "./Star";

const Modal = ({ showModal, setShowModal, productData }) => {
    const [modalStarsState, setModalStarsState] = useState({
        hoveredOverStarNumber: 0,
        leftContainer: false,
        rated: 0
    });

    const { hoveredOverStarNumber, leftContainer, rated } = modalStarsState;

    const [formData, setFormData] = useState({
        rated: 0,
        text: ''
    });

    // Set rating state when user clicks on a star
    const onClick = () => {
        setModalStarsState((prevState) => ({
            ...prevState,
            rated: modalStarsState.hoveredOverStarNumber
        }));
        setFormData((prevState) => ({
            ...prevState,
            rated: modalStarsState.hoveredOverStarNumber
        }));
    };

    // Color stars based on users mouse position
    const onMouseMove = (e, i) => {
        const rect = e.target.getBoundingClientRect();
        if (e.clientX - rect.left < 15) {
            setModalStarsState((prevState) => ({ ...prevState, hoveredOverStarNumber: i + 0.5, leftContainer: false }));
        } else {
            setModalStarsState((prevState) => ({ ...prevState, hoveredOverStarNumber: i + 1, leftContainer: false }));
        }
    };

    // When users leaves the stars container reset stars color
    const onMouseLeave = () => {
        setModalStarsState((prevState) => ({ ...prevState, leftContainer: true }));
    };

    // Render stars
    const renderModalRatingSystem = (score, key) => {
        const arr = [];

        // If user hovers over star or clicks on one, add specific classes
        let classes = (i) => {
            let arr = [];

            if (score > i || (modalStarsState.hoveredOverStarNumber > i + 0.5 && modalStarsState.leftContainer !== true)) {
                arr.push("golden");
            } else if (modalStarsState.rated > i + 0.5) {
                arr.push("rated");
            }

            return arr.join(' ');
        };

        for (let i = 0; i < 5; i++) {
            arr.push(
                <Star className={classes(i)}
                    onMouseMove={(e) => onMouseMove(e, i)}
                    onClick={() => onClick(i)}
                    hoveredOverStarNumber={hoveredOverStarNumber}
                    leftContainer={leftContainer}
                    rated={rated}
                    key={i}
                    index={i} />
            );
        }

        return arr;
    };

    // Submit rating after submit button press
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { error } = await supabase
            .from('reviews')
            .insert([
                { score: formData.rated, text: formData.text, product_id: productData.id },
            ]);

        if (!error) {
            setShowModal(false);
            setFormData({
                rated: 0,
                text: ''
            });
        }
    };

    // Close modal & reset rating on ESC key press
    const onKeyPress = (e) => {
        if (e.keyCode === 27) {
            setModalStarsState((prevState) => ({ ...prevState, rated: 0 }));
            setShowModal(false);
        };
    };

    const inputRef = useRef(null);

    // Focus on the input when modal opens
    useEffect(() => {
        inputRef.current.focus();
    });

    return <div id="overlay" style={showModal ? { display: 'flex' } : { display: 'none' }} onKeyDown={onKeyPress} onClick={() => inputRef.current.focus()}>
        <div id="modal">
            <h2 className="title">What's your rating?</h2>
            <form onSubmit={handleSubmit}>
                <label htmlFor="rating">Rating</label>
                <div className="container-stars" onMouseLeave={onMouseLeave}>
                    {renderModalRatingSystem()}
                </div>
                <label htmlFor="review">Review</label>
                <input value={formData.text} ref={inputRef} name="review" placeholder="Start typing..." onChange={(e) => setFormData((prevState) => ({ ...prevState, text: e.target.value }))} />
                <button type="submit" className="btn">Submit review</button>
            </form>
        </div>
    </div>;
};

export default Modal;