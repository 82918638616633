import Star from "../components/Star";

const renderStars = (score) => {
    const arr = [];

    for (let i = 0; i < 5; i++) {
        arr.push(<Star className={score > i + 0.5 ? "golden" : ""} key={i} index={i} score={score} />);
    }
    return arr;
};

export default renderStars;