import renderStars from "../lib/renderStars";

const RatingContainer = ({ productData, setShowModal }) => {
    return <div id="container-rating">
        <h2 id="score">{productData.score ? productData.score.toFixed(1) : ''}</h2>
        <div className="container-stars">
            {renderStars(productData.score).map(star => star)}
        </div>
        <button className="btn" id="btn-review" onClick={() => setShowModal(true)}>Add review</button>
    </div>;
};

export default RatingContainer;