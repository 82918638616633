import { supabase } from "./initSupabase";

export const getProductData = async () => {
    let { data: products, error } = await supabase.from('products').select(`id, title, reviews:reviews (id, score, text)`).eq('id', 1);

    if (!error) {
        let product = products[0];
        let total = 0;

        for (let i = 0; i < product.reviews.length; i++) {
            total += product.reviews[i].score;
        }

        let score = total / product.reviews.length;
        return { ...products[0], score };
    }
};